import React, { memo } from 'react';
import { addressFormats } from '../../../EditAddress/AddressConstants';
import NewLine from '../NewLine';

const FormattedAddress = ({ address }) => {
  return (
    <>
      {address && (
        <p>
          <NewLine list={[address.format]} />
          <NewLine list={[address.careOf?.prefix, address.careOf?.name]} />

          {address.format === addressFormats.Street ? (
            <>
              <NewLine
                list={[
                  address.streetName,
                  address.houseNumber,
                  address.houseLetter,
                  address.apartmentNumber,
                ]}
              />
              <NewLine list={[address.additionalAddress]} />
            </>
          ) : address.format === addressFormats.Foreign ? (
            <>
              <NewLine list={[address.addressLine1]} />
              <NewLine list={[address.addressLine2]} />
              <NewLine list={[address.addressLine3]} />
            </>
          ) : address.format === addressFormats.Freetext ? (
            <>
              <NewLine list={[address.additionalAddress]} />
            </>
          ) : address.format === addressFormats.POBox ? (
            <>
              <NewLine list={[address.number, address.facilityName]} />
            </>
          ) : (
            <>
              <NewLine list={[address.addressLine1]} />
              <NewLine list={[address.addressLine2]} />
              <NewLine list={[address.addressLine3]} />
            </>
          )}

          {address.city && <NewLine list={[address.city]} />}

          {address.format === addressFormats.Foreign ? (
            <NewLine list={[address.country?.name]} />
          ) : (
            <NewLine
              list={[address.country?.name, address.city, address.postalCode]}
            />
          )}
        </p>
      )}
    </>
  );
};

export default memo(FormattedAddress);
